import React from 'react';
import { CarouselComp, NewCarousel } from '../../index';

export const BannerHome = () => {
	return (
		<div className="bg-gray-100">
			{/* <CarouselComp /> */}
			<NewCarousel />
		</div>
	);
};
