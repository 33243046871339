import { desa, pariwisata, pendidikan, pertanian } from '@/images/carousel_image';

export const CarouselData = [
	{
		image: desa
	},
	{
		image: pariwisata
	},
	{
		image: pendidikan
	},
	{
		image: pertanian
	}
];
