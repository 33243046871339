import React, { forwardRef } from 'react';
import ReactSelect from 'react-select';
import './InputSelect.css';

export const InputSelect = forwardRef(
	({ options, value, loading, multi, searchable, clearable, disabled, onChange, placeholder, ...props }, ref) => {
		return (
			<ReactSelect
				{...props}
				ref={ref}
				value={options?.filter((option) => (multi ? value?.includes(option.value) : option.value === value))}
				className={`z-10 ${props.className}`}
				placeholder={placeholder}
				onChange={onChange}
				isMulti={multi}
				isSearchable={searchable}
				isLoading={loading}
				isDisabled={disabled}
				isClearable={clearable}
				styles={{
					input: (provided) => ({
						...provided,
						'input:focus': {
							boxShadow: 'none',
							zIndex: 20
						}
					}),
					menu: (styles) => ({ ...styles, zIndex: 30 }),
					menuPortal: (styles) => ({ ...styles, zIndex: 30 })
				}}
				menuPortalTarget={document.body}
				options={multi ? options?.filter((option) => !value?.includes(option.value)) : options}
			/>
		);
	}
);

InputSelect.displayName = 'InputSelect';
InputSelect.defaultProps = {
	clearable: true
};
